import * as React from 'react';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import { IFooterContainerProps } from '../FooterContainer.types';

const FooterContainer: React.FC<IFooterContainerProps> = props => {
  const { id, skin: FooterContainerClass, children, meshProps } = props;

  return (
    <FooterContainerClass wrapperProps={{ id, tagName: 'footer' }}>
      <MeshContainer id={id} {...meshProps}>
        {children}
      </MeshContainer>
    </FooterContainerClass>
  );
};

export default FooterContainer;
