import * as React from 'react';

import { IWRichTextProps } from '../WRichText.types';
import { TestIds } from '../constants';
import style from './style/WRichText.scss';

const WRichText: React.FC<IWRichTextProps> = props => {
  const { id, html, skin = 'WRichTextSkin' } = props;

  const skinsWithContainer: Array<IWRichTextProps['skin']> = [
    'WRichTextSkin',
    'WRichTextClickableSkin',
  ];
  const isInContainer = skinsWithContainer.includes(skin);

  return isInContainer ? (
    <div id={id} className={style[skin]} data-testid={TestIds.richTextElement}>
      <div
        className={style.richTextContainer}
        dangerouslySetInnerHTML={{ __html: html }}
        data-testid={TestIds.containerElement}
      />
    </div>
  ) : (
    <div
      id={id}
      className={style[skin]}
      dangerouslySetInnerHTML={{ __html: html }}
      data-testid={TestIds.richTextElement}
    />
  );
};

export default WRichText;
